<script setup>
const props = defineProps({
  href: [Object,String],
  target: String
})
</script>

<template>
  <NuxtLink
    :to="props.href"
    :target="target"
    class="font-extralight text-sm hover:text-azure cursor-pointer transition-colors hidden md:flex justify-center items-center"
  >
      <slot />
  </NuxtLink>
</template>
