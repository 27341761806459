<script setup>

const updateDate = dateToTextPL(getLastWorkingDayDate(), false);
const infoBarItems = [
  {caption: 'tylko <strong>DOSTĘPNE</strong> samochody', duration: 5000},
  {caption: `ostatnia aktualizacja: <b>${updateDate} 06:00</b>`, duration: 5000}
]

const infoBarLabel = ref(infoBarItems[0].caption);
const show = ref(true);

let currentIndex = 0;

const getNextInfo = () => {
  infoBarLabel.value = infoBarItems[currentIndex].caption;
  setTimeout(() => {
    show.value = true;
    setTimeout(() => {
      currentIndex++;
      if (currentIndex > infoBarItems.length - 1) {
        currentIndex = 0;
      }
      show.value = false;
      setTimeout(() => {
        getNextInfo();
      }, 160)

    }, infoBarItems[currentIndex].duration)
  }, 160)
}

onMounted(() => {
  getNextInfo();
})

</script>

<template>
  <div class="flex w-full bg-black py-2.5 justify-center items-center min-w-80">
    <div :class="['text-white transition-opacity leading-tight font-extralight text-base',{'opacity-100':show, 'opacity-0':!show}]" v-html="infoBarLabel"></div>
  </div>
</template>
