<script setup>
import menus from "@/configs/menu";

const emits = defineEmits(['hamburgerClick'])
const clickMobileMenu = () => {
  emits('hamburgerClick');
}
const utm = useUtm();
const queryUtmParameters = ref('');
onMounted(() => {
  queryUtmParameters.value = utm.getQueryUtmParameters();
})
</script>

<template>
  <div class="w-full bg-white">
    <div class="flex justify-between items-center border-b border-b-gray-200 py-2">
      <div class="hidden lg:block">
        <div class="font-extralight text-sm leading-[150%] tracking-[0.42px]">
          <b>wszystkie salony</b> pod jednym adresem
        </div>
      </div>
      <div class="flex gap-6 justify-between lg:justify-normal w-full lg:w-auto">
        <ButtonDropDown class="-mx-3" :items="[
            {label: 'Samochód z finansowaniem', href: {name: 'index'}},
            {label: 'Tylko finansowanie', href: {name: 'static-financing-only'}}
        ]" />
        <div class="flex gap-6">
          <MenuItem v-for="menu in menus" :href="menu.path">{{ menu.label }}</MenuItem>
          <MenuItem :href="'https://vehis.pl/moto' + queryUtmParameters" target="_blank">
            <VectorMoto />
          </MenuItem>
          <ButtonCompare />
          <IconHamburger class="md:hidden" @click="clickMobileMenu"/>
        </div>
      </div>
    </div>
  </div>
</template>
